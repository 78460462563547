<template>
  <img
    v-if="!loading"
    class="help"
    src="./help.png"
    alt=""
    @click="tipShow = true"
  />
  <img
    v-if="!loading"
    class="reset"
    @click="onReset"
    src="./reset.png"
    alt=""
  />
  <input
    v-if="!loading"
    class="range"
    type="range"
    max="1"
    min="0"
    step="0.05"
    value="0.45"
    @input="onInput"
  />

  <div class="tips" v-show="tipShow && !loading">
    <img class="close" src="./close.png" alt="" @click="tipShow = false" />
    <h3>操作说明</h3>
    <p>
      1. 单指滑动可旋转模型视角 <br />
      2. 双指捏合可缩放或放大模型 <br />
      3. 双指滑动可拖拽模型，调整模型位置 <br />
      4. 上下拖拽右侧进度条可设置缩放速度
    </p>
    <p class="img"><span>帮助：</span><img src="./help.png" alt="" /> <br /></p>
    <p class="img"><span>复位：</span><img src="./reset.png" alt="" /></p>
  </div>
  <div class="viewer-container">
    <Loading :show="loading" :text="loadingText" />
    <div v-if="!loading" class="tab-list">
      <div
        class="btn"
        :class="{ active: active === item.key }"
        v-for="item of tabs"
        :key="item.key"
        @click="tabChange(item.key)"
      >
        {{ item.name }}
      </div>
    </div>
    <!--    <div-->
    <!--      v-if="!loading"-->
    <!--      class="tab-modal"-->
    <!--      @click="isIsolationActivated = !isIsolationActivated"-->
    <!--    >-->
    <!--      <div v-if="isIsolationActivated">-->
    <!--        <img src="../assets/fullscreen-shrink.svg" alt="" />-->
    <!--      </div>-->
    <!--      <div v-else>-->
    <!--        <img src="../assets/full.svg" alt="" />-->
    <!--      </div>-->
    <!--    </div>-->
    <div
      class="info-swap"
      :class="{ 'list-active': listActive, full: isIsolationActivated }"
    >
      <img
        class="toggle-icon"
        :class="{ rotate: isIsolationActivated }"
        @click="isIsolationActivated = !isIsolationActivated"
        src="./jt.svg"
      />
      <div class="info-box">
        <div class="info-list">
          <div class="info-item" v-for="(item, i) of userList" :key="i">
            <span class="label">{{ item.key }}</span>
            <span class="value">{{ item.value || "-" }}</span>
          </div>
          <div class="info-item" v-for="(item, i) of list" :key="i">
            <span class="label">{{ item.cn }}</span>
            <span class="value">{{ sources ? sources[item.key] : "-" }}</span>
          </div>

          <!--        <div v-for="(item, i) of sources" :key="i">-->
          <!--          <div class="info-item" @click="groupClick(item)">-->
          <!--            <span class="label">{{ item.group }}</span>-->
          <!--            <span :class="{ rotate: itemActive === item.group }"-->
          <!--              ><img src="./jt.svg" alt=""-->
          <!--            /></span>-->
          <!--          </div>-->
          <!--          <div-->
          <!--            v-if="itemActive === item.group"-->
          <!--            v-for="(m, k) of item.items"-->
          <!--            class="info-item-content"-->
          <!--            :key="k"-->
          <!--          >-->
          <!--            <span class="lb">{{ m.key }}</span>-->
          <!--            <span class="val">{{ m.value }}</span>-->
          <!--          </div>-->

          <!--        </div>-->
        </div>
      </div>
    </div>

    <div ref="viewerRef" id="dxf-viewer"></div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import axios from "axios";
import Loading from "@/components/Loading.vue";

const viewerRef = ref(null);

const loading = ref(true);
const loadingText = ref("网络加载中...");

const active = ref(1);
const tabs = [
  {
    key: 1,
    name: "显示构件",
  },
  {
    key: 2,
    name: "显示全部",
  },
];

const listActive = ref(false);
const list = window.global_List;
const userList = ref([]);
const sources = ref();
const itemActive = ref("");

const getAccessToken = async () => {
  const AppKey = window.global_AppKey;
  const AppSecret = window.global_AppSecret;
  const Authorization = `Basic ${btoa(AppKey + ":" + AppSecret)}`;
  const res = await axios.post(
    window.global_Url + "/prod-api/backend/model/getAccessToken",
    null,
    {
      headers: {
        Authorization,
      },
    }
  );
  return res.data.data;
};
const getViewToken = async (accessToken) => {
  const url = new URL(window.location.href);
  const fileId = url.searchParams.get("fileid");
  if (!fileId) {
    alert("地址错误！");
    return;
  }
  const res = await axios.post(
    window.global_Url +
      `/prod-api/backend/model/getViewToken?fileId=${fileId}&accessToken=${accessToken}`
  );
  return res.data.data;
};

let viewer3D;
let model3D;
let app;
let accessToken = "";
let ids = [];
let defaultColor = "";
let activeColor = "";
let defaultPos;
let camera = null;

onMounted(async () => {
  accessToken = await getAccessToken();
  const viewToken = await getViewToken(accessToken);

  init(viewToken);
});
async function getDataById() {
  const url = new URL(window.location.href);
  const modelCode = url.searchParams.get("modelCode");
  const modelGroupName = url.searchParams.get("modelGroupName");
  const fileId = url.searchParams.get("fileid");
  const res = await axios.get(
    window.global_Url +
      `/prod-api/backend/model/queryByGroupName?modelCode=${modelCode}&modelGroupName=${modelGroupName}&fileId=${fileId}`
  );
  ids = JSON.parse(res.data.data.modelCodes);
  ids = ids.map((id) => id.toString());
  // ids = ["464353", "464251", "462475"];
  let data = res.data.data.modelInfo
    ? JSON.parse(res.data.data.modelInfo).properties
    : [];
  const extData = res.data.data.remark ? JSON.parse(res.data.data.remark) : [];
  const extDataMap = extData.reduce((pre, cur) => {
    pre[cur.key] = cur.value;
    return pre;
  }, {});

  console.log(res);
  const dataMap = data.reduce((pre, cur) => {
    pre = {
      ...pre,
      ...cur.items.reduce((p, c) => {
        p[c.key] = c.value;
        return p;
      }, {}),
    };
    return pre;
  }, {});
  sources.value = {
    ...list.reduce((pre, cur) => {
      pre[cur.key] = dataMap[cur.key] || "-";
      return pre;
    }, {}),
    ...extDataMap,
    ...res.data.data,
    modelGroupName,
  };
}

function init(viewToken) {
  let loaderConfig = new BimfaceSDKLoaderConfig();
  loaderConfig.viewToken = viewToken;

  BimfaceSDKLoader.load(loaderConfig, successCallback, failureCallback);
  function successCallback(viewMetaData) {
    let webAppConfig = new Glodon.Bimface.Application.WebApplication3DConfig();
    webAppConfig.domElement = viewerRef.value;
    webAppConfig.Toolbars = [];
    app = new Glodon.Bimface.Application.WebApplication3D(webAppConfig);
    app.addView(viewToken);
    viewer3D = app.getViewer();

    loading.value = false;

    viewer3D.addEventListener(
      Glodon.Bimface.Viewer.Viewer3DEvent.ViewAdded,
      () => {
        const red = new Glodon.Web.Graphics.Color("#FF0000", 1);
        const blue = new Glodon.Web.Graphics.Color("#32CD99", 1);
        activeColor = new Glodon.Web.Graphics.Color("#142dc8", 1);
        defaultColor = new Glodon.Web.Graphics.Color("#8e8e8e", 0.3);

        model3D = viewer3D.getModel();

        viewer3D.showAllAxisGrids();
        viewer3D.setGridBubblesColor(red);
        viewer3D.setGridLinesColor(blue);

        // 阻尼
        // viewer3D.enableDamping(true);

        // 禁用右键菜单
        viewer3D.toggleContextMenuDisplay(false);

        // 获取相机 禁止平移
        camera = viewer3D.getCamera();
        // camera.enablePan(false);

        // 设置天空盒
        // viewer3D.enableSkyBox(true);
        // getComponentProperty();
        defaultPos = viewer3D.getCameraStatus();
        setCameraStatus();
        lightItem();

        viewer3D.addEventListener(
          Glodon.Bimface.Viewer.Viewer3DEvent.MouseClicked,
          (data) => {
            if (active.value === 1) {
              model3D.clearSelectedComponents();
              model3D.addSelectedComponentsById([...ids]);
            } else {
              model3D.clearSelectedComponents();
            }
          }
        );
      }
    );
  }

  function failureCallback(error) {
    loadingText.value = "加载失败,请检查网络并刷新重试！";
  }
}

function setCameraStatus() {
  const curDefaultPos = viewer3D.getCameraStatus();
  const position = defaultPos.target;
  viewer3D.setCameraStatus({
    ...curDefaultPos,
    target: {
      ...position,
      z: -10,
    },
  });
}

function tabChange(key) {
  active.value = key;
  if (key === 1) {
    setCameraStatus();
    lightItem();
  }
  if (key === 2) {
    lightAll();
  }
}

function onReset() {
  // setCameraStatus();
  model3D.zoomToSelectedComponents(active.value === 1 ? 3 : 1);
}

async function lightItem() {
  viewer3D.setNavigationMode(Glodon.Bimface.Viewer.NavigationMode3D.Select);
  // 设置默认颜色
  model3D.overrideAllComponentsColor(defaultColor);
  viewer3D.render();
  // 获取构件属性
  await getDataById();
  listActive.value = true;
  try {
    // 强调效果
    // viewer3D.addBlinkComponentsById([...ids]);
    // viewer3D.setBlinkColor(new Glodon.Web.Graphics.Color("#ffe238", 1));
    // viewer3D.enableBlinkComponents(true);
    // viewer3D.setBlinkIntervalTime(500);

    model3D.overrideComponentsColorById([...ids], activeColor);
    model3D.addSelectedComponentsById([...ids]);

    viewer3D.render();
  } catch (e) {
    console.log(e);
  }
}
function lightAll() {
  listActive.value = false;
  // 清除隔离
  model3D.clearOverrideColorComponents();
  // 渲染三维模型
  viewer3D.render();
  // 定位还原
  viewer3D.setView(Glodon.Bimface.Viewer.ViewOption.Home);
  // 清除构件选中状态
  model3D.clearSelectedComponents();

  viewer3D.setNavigationMode(
    Glodon.Bimface.Viewer.NavigationMode3D.OrbitWithModelCenter
  );
}

const isIsolationActivated = ref(false); // 是否全屏
// function isolateComponents() {
//   if (!isIsolationActivated.value) {
//     viewer3D.showAllAxisGrids();
//     isIsolationActivated.value = true;
//   } else {
//     viewer3D.removeAllAxisGrids();
//     isIsolationActivated.value = false;
//   }
// }

const tipShow = ref(true);

function onInput(e) {
  const val = e.target.value;
  camera.setZoomSpeed(+val);
}
</script>
<style lang="less" scoped>
.rotate {
  transform: rotate(360deg) !important;
}
.full {
  max-height: 100vh !important;
}
.viewer-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: rgb(247, 250, 255);

  canvas,
  #dxf-viewer {
    display: block;
    border: none;
    height: 100vh;
    width: 100vw;
  }

  .tab-list {
    position: fixed;
    z-index: 997;
    top: 10%;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    width: 160px;
    height: 28px;
    line-height: 28px;
    border-radius: 10px;
    border: 1px solid #4797e1;
    transition: all 0.8s;

    .btn {
      flex: 1;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      transition: all 1s;
    }
    > div:nth-child(1) {
      border-radius: 8px 0 0 8px;
    }
    > div:nth-child(2) {
      border-radius: 0 8px 8px 0;
    }
  }
  .active {
    color: #fff;
    background: #4797e1;
  }

  .tab-modal {
    position: fixed;
    bottom: 32vh;
    right: 0;
    background: #4797e1;
    padding: 2px;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    transition: all 0.8s;
    z-index: 999;
    width: 24px;
    height: 24px;
    overflow: hidden;
    > div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .list-active {
    transform: scaleY(1) !important;
  }
  .info-swap {
    position: fixed;
    z-index: 998;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 0;
    max-height: 30vh;
    background: #eee;
    transform: scaleY(0);
    transform-origin: bottom;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.3s;

    .toggle-icon {
      position: absolute;
      padding: 10px;
      margin-top: -24px;
      margin-left: -20px;
      transform: rotate(180deg);
    }

    .info-box {
      height: 100%;

      .info-list {
        height: 100%;
        padding: 0 10px;
        background: #fff;
        border-radius: 10px 10px 0 0;

        .info-item,
        .info-item-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          min-height: 30px;
          border-bottom: 1px solid #eee;
          &:last-child {
            border: none;
          }

          .label {
            white-space: nowrap;
          }

          .value {
            color: #636262;
            word-break: break-word;
          }
        }
        .info-item-content {
          padding: 0 10px;
          font-size: 12px;
          background: #eee;
          border-bottom: 1px solid #fff !important;
        }
      }
    }
  }
}
.tips {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20vh;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  h3 {
    font-size: 16px;
    color: #fff;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    color: #fff;
    line-height: 2;
  }
  .img {
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
}
.help {
  position: fixed;
  z-index: 999;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.reset {
  position: fixed;
  z-index: 999;
  top: 40px;
  right: 10px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  &:active {
    opacity: 0.8;
  }
}
.range {
  position: fixed;
  top: 130px;
  z-index: 99;
  right: -30px;
  width: 100px;
  transform: rotate(-90deg);
  background-color: #4797e1;
}
</style>
